@import '../base/vars.scss';

.light-mode {
    --color-txt-p: #{$color-txt-p-lm};
    --color-txt-s: #{$color-txt-s-lm};
    --color-txt-t: #{$color-txt-t-lm};

    --color-layout-p: #{$color-layout-p-lm};
    --color-layout-s: #{$color-layout-s-lm};
    --color-layout-t: #{$color-layout-t-lm};
    --color-layout-header: #{$color-layout-header-lm};
    --color-layout-foreground: #{$color-layout-foreground-lm};
    --color-layout-box: #FAF7F7;

    --color-action-active: #{$color-action-active-lm};
    --color-action-hover: #{$color-action-hover-lm};
    --color-action-selected: #{$color-action-selected-lm};

    --color-disabled-p: #{$color-disabled-p-lm};
    --color-disabled-s: #{$color-disabled-s-lm};

    --color-divider: #{$color-divider-lm};
}

.dark-mode {
    --color-txt-p: #{$color-txt-p-dm};
    --color-txt-s: #{$color-txt-s-dm};
    --color-txt-t: #{$color-txt-t-dm};

    --color-layout-p: #{$color-layout-p-dm};
    --color-layout-s: #{$color-layout-s-dm};
    --color-layout-t: #{$color-layout-t-dm};
    --color-layout-header: #{$color-layout-header-dm};
    --color-layout-foreground: #{$color-layout-foreground-dm};
    --color-layout-box: #{$color-layout-box-dm};

    --color-action-active: #{$color-action-active-dm};
    --color-action-hover: #{$color-action-hover-dm};
    --color-action-selected: #{$color-action-selected-dm};

    --color-disabled-p: #{$color-disabled-p-dm};
    --color-disabled-s: #{$color-disabled-s-dm};

    --color-divider: #{$color-divider-dm};
}