@import '../../styles/base/vars.scss';
@import '../../styles/modules/mode.scss';

section {

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    .sectionBox {
        width: 100%;
        border-radius: 6px;
        background-color: var(--color-layout-box);
        box-shadow: #{$color-box-shadow-s};

        transition: #{$transition-theme};

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    
        .sectionBoxHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 0 24px;
            width: 100%;
            padding: 12px 24px;
            border-bottom: 2px solid var(--color-divider);

            transition: #{$transition-theme};

            .sectionBoxHeaderLeftContent {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0 8px;

                .sectionBoxHeaderGoBack {
                    width: 24px;
                    height: 24px;

                    cursor: pointer;

                    &:hover svg path {
                        fill: var(--color-txt-p) !important;
                    }

                    svg path {
                        fill: var(--color-txt-t) !important;

                        transition: #{$transition-default};
                    }

                }

                h2 {
                    color: var(--color-txt-t);
                    font-size: 18px;
    
                    transition: #{$transition-theme};
                }

            }

            .sectionBoxHeaderRightContent {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0 16px;

                .MuiInput-underline {

                    &:before {
                        border-color: var(--color-txt-t);
                    }
            
                    &:after {
                        border-color: #{$color-theme-p};
                    }
            
                    input {
                        color: var(--color-txt-p);
            
                        &::placeholder {
                            color: var(--color-txt-t);
                        }
            
                    }
            
                    svg path {
                        fill: var(--color-txt-p) !important;
                    }
            
                }
            
                .MuiSelect-selectMenu {
                    color: var(--color-txt-p);
                }

                .btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    padding: 8px 16px;
                    border: 1px solid #{$color-theme-p};
                    border-radius: 8px;
                
                    font-size: 14px;
                    font-weight: 700;
                
                    cursor: pointer;
                    transition: #{$transition-default};
                
                    &.default {
                        background-color: #{$color-theme-p};
                
                        color: #{$color-txt-p-dm};
                
                        &:hover {
                            border-color: #{$color-theme-p-dark};
                            background-color: #{$color-theme-p-dark};
                        }
                
                    }
                
                    &.outline {
                        background-color: transparent;
                
                        color: #{$color-theme-p};
                
                        &:hover {
                            background-color: #{$color-theme-p-hover};
                        }
                
                    }
                
                    &.status {
                        border-color: var(--color-status-p) !important;
                        background-color: var(--color-status-p) !important;
                
                        color: #{$color-txt-p-dm} !important;
                
                        &.disabled {
                            pointer-events: none;
                        }
                
                        &:hover {
                            background-color: var(--color-status-t) !important;
                        }
                
                    }
            
                    svg path {
                        fill: #{$color-txt-p-dm} !important;
                    }
                
                }

                .sectionBoxHeaderOption {
                    width: 28px;
                    height: 28px;

                    svg {
                        cursor: pointer;

                        path {
                            transition: #{$transition-default};
                        }

                    }
                    
                }

            }

        }

        .sectionBoxContent {
            display: flex;
            flex-direction: column;
            width: 100%;

            &.padding {
                padding: 24px;
            }

        }

        .sectionBoxDataContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            border: 2px solid var(--color-divider);
            border-radius: 10px;

            transition: #{$transition-theme};

            &:not(:last-of-type){
                margin-bottom: 16px;
            }

            .sectionBoxDataTitle {
                padding: 16px 16px 0;
                
                color: var(--color-txt-s);
                font-size: 18px;
                font-weight: bold;

                transition: #{$transition-theme};
            }

            p {
                color: var(--color-txt-t);
                font-size: 16px;

                transition: #{$transition-theme};
            }

            .sectionBoxDataContent {
                flex-grow: 1;
                width: 100%;

                &.padding {
                    padding: 16px;
                }

            }

        }
    
    }

}

.light-mode section {

    .sectionBox {

        .sectionBoxHeaderOption svg {

            &:hover path {
                fill: #{$color-theme-p-dark} !important;
            }

            path {
                fill: #{$color-theme-p} !important;
            }

        }

    }
    
}

.dark-mode section {

    .sectionBox {

        .sectionBoxHeader {

            .MuiInput-underline:after {
                border-bottom-color: var(--color-txt-p);
            }

        }

        .sectionBoxHeaderOption svg {

            &:hover path {
                fill: var(--color-txt-p) !important;
            }

            path {
                fill: var(--color-txt-t) !important;
            }

        }

        .sectionBoxHeaderRightContent {

            .btn {

                &.outline {
                    border-color: var(--color-txt-p) !important;
        
                    color: var(--color-txt-p) !important;
                }
        
                &.status {
                    border-color: var(--color-status-p) !important;
                    background-color: var(--color-status-p) !important;
        
                    color: #{$color-txt-p-dm} !important;
        
                    &:hover {
                        background-color: var(--color-status-t) !important;
                    }
        
                }
        
            }

        }

    }

}