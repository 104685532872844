@import '../../styles/base/vars.scss';
@import '../../styles/modules/mode.scss';

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    margin-top: 24px;

    .paginationItem {
        color: var(--color-txt-t);
        
        cursor: pointer;

        &:hover {
            color: var(--color-txt-p);
            text-decoration: underline;
        }

        &.active {
            color: var(--color-txt-s);
            font-weight: 700;
            text-decoration: underline;
        }

    }

}