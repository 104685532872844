@import '../base/vars.scss';
@import '../modules/mode.scss';

/*******************************************************
	* - V1
*******************************************************/

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
}

/*******************************************************
	Link - V5
*******************************************************/
.negrito{
    font-weight: bold;
}
a {		
	border: none;
	outline: none;

	color: none;
	text-decoration: none;

	cursor: pointer;

    &:link, :active, :visited, :hover, :focus {
        border: none;
        outline: none;
    
        color: none;
        text-decoration: none;
    }

}

/*******************************************************
	Input - V3
*******************************************************/

input, textarea {
	border: none;
	outline: none;

    &:focus {
        outline: none;
    }

    &:-webkit-autofill {
        transition: color 9999s ease-out, background-color 9999s ease-out;
        transition-delay: 9999s;
        -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
        -webkit-transition-delay: 9999s;

        &:hover, &:focus, &:active {
            transition: color 9999s ease-out, background-color 9999s ease-out;
            transition-delay: 9999s;
            -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
            -webkit-transition-delay: 9999s;
        }
        
    }

}

select {

    &:focus {
        outline: none;
    }

}

/*******************************************************
	Image - V2
*******************************************************/

img {
	width: 100%;
	height: auto;
    vertical-align: top;

    font-size: 16px;
}

/*******************************************************
	Text - V3
*******************************************************/

p, h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;

	font-weight: normal;
}

/*******************************************************
	List - V1
*******************************************************/

ul, li {
    padding: 0;
    margin: 0;

    list-style: none;
}

/*******************************************************
	Button - V1
*******************************************************/

button {
	border: none;

    &:hover, &:focus {
        outline: none;
    }

}

/*******************************************************
	Table - V1
*******************************************************/

.tableWrapper {
    display: table;
    table-layout: fixed;
    width: 100%;

    .tableContainer {
        display: table-cell;
        overflow: auto;
        width: 100%;

        &::-webkit-scrollbar {
            width: 12px;
        }
    
        &::-webkit-scrollbar-track {
            border-radius: 6px; 
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: rgba(0,0,0,0.4); 
    
            &:hover {
                background: rgba(0,0,0,0.6); 
            }
    
        }

    }

}

table svg.status path {
    fill: var(--color-status-p) !important;
}

table.customTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead, tbody {
        width: 100%;
    }

    tr {
        width: 100%;

        &.selected {
            background-color: var(--color-action-hover);

            td {
                color: var(--color-txt-p) !important;
            }

        }

        th, td {
            padding: 8px;
            border-right: 2px solid var(--color-divider);
            border-bottom: 2px solid var(--color-divider);

            color: var(--color-txt-t);
            text-align: center;
        }

        th {
            border-top: 2px solid var(--color-divider);

            font-size: 16px;
            font-weight: bold;

            &:first-child {
                border-left: 2px solid var(--color-divider);
            }

            &:first-child, &:last-child {
                border-top: 2px solid var(--color-divider);
            }

        }

        td {
            font-size: 14px;

            &:first-child {
                border-left: 2px solid var(--color-divider);
            }

            div {

                &.center {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }

            }

        }

        &:first-child th {

            &:first-child {
                border-top-left-radius: 6px;
            }
    
            :last-child {
                border-top-right-radius: 6px;
            }

        }

        &:last-child td {

            &:first-child {
                border-bottom-left-radius: 6px;
            }

            &:last-child {
                border-bottom-right-radius: 6px;
            }

        }

    }

}

.MuiTableContainer-root  {
    overflow: overlay !important;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px; 
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: rgba(0,0,0,0.4); 

        &:hover {
            background: rgba(0,0,0,0.6); 
        }

    }

}

.MuiTable-root {

    * {
        transition: #{$transition-theme};
    }

    th {
        background-color: var(--color-layout-s) !important;
    }

    th, td {
        color: var(--color-txt-t) !important;
        text-align: center !important;
    }

    tr.selected {
        background-color: var(--color-action-hover) !important;

        td {
            color: var(--color-txt-p) !important;
        }

    }

    .MuiTableCell-root {

        &.status {
            background-color: var(--color-status-t) !important;

            color: #{$color-txt-p-dm} !important;
            font-weight: bold;
        }

    }

}

.dark-mode {

    .MuiTable-root {

        th {
            background-color: var(--color-layout-t) !important;
        }

    }

}


/*******************************************************
	Elements
*******************************************************/

html, body, header, main, footer, section,
#root, #wrapper,
#headerContainer, #mainContainer, #footerContainer, .sectionContainer {
    width: 100%;

    transition: #{$transition-theme};
}

/*******************************************************
	Dialog
*******************************************************/

.dialogContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/*******************************************************
	Scroll
*******************************************************/

.scroll {
    overflow: hidden;

    &:hover {
        overflow: overlay !important;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px; 
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: rgba(0,0,0,0.4); 

        &:hover {
            background: rgba(0,0,0,0.6); 
        }

    }

}

.overlayScroll {
    overflow: overlay !important;
}

.customScroll {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px; 
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: rgba(0,0,0,0.4); 

        &:hover {
            background: rgba(0,0,0,0.6); 
        }

    }

}

@supports not (overflow: overlay) {

    .scroll {
        overflow: auto !important;
    }
    
}