@import '../../styles/base/vars.scss';
@import '../../styles/modules/mode.scss';
@import '../../styles/modules/status.scss';

form {
    width: 100%;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    &:not(:last-of-type){
        margin-bottom: 24px;
    }

    &.padding {
        padding: 24px;
    }

    .formContainerTitle {
        margin-bottom: 16px;

        color: var(--color-txt-s);
        font-weight: bold;

        transition: #{$transition-theme};
    }

    .formRow {
        display: grid;
        gap: 24px;
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }

    }

    .formGroup {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        width: 100%;

        label {
            color: var(--color-txt-s);
            font-size: 14px;
            font-weight: bold;

            transition: #{$transition-theme};
        }

    }

    input, textarea {
        width: 100%;
        padding: 8px;
        border: 2px solid var(--color-divider);
        border-radius: 10px;
        background-color: var(--color-layout-p);

        color: var(--color-txt-p);
        font-size: 14px;

        resize: none;
        transition: #{$transition-theme};

        &:not(.disabled){

            &:hover, &:focus {
                border-color: #{$color-theme-p};
            }

        }

        &::-webkit-calendar-picker-indicator {
            filter: invert(40%);

            cursor: pointer;

            &:hover {
                filter: invert(80%);
            }

        }

        &.status {

            &.disabled {
                color: var(--color-txt-t) !important;
            }

        }

    }

    .formActionsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0 16px;
        width: 100%;
        margin-top: 24px;

        button {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            padding: 12px 32px;
            border: 1px solid #{$color-theme-p};
            border-radius: 8px;

            font-size: 14px;
            font-weight: 700;

            cursor: pointer;
            transition: #{$transition-default};

            &.default {
                background-color: #{$color-theme-p};

                color: #{$color-txt-p-dm};

                &:hover {
                    border-color: #{$color-theme-p-dark};
                    background-color: #{$color-theme-p-dark};
                }

            }

            &.outline {
                background-color: transparent;

                color: #{$color-theme-p};

                &:hover {
                    background-color: #{$color-theme-p-hover};
                }

            }

            &.status {
                border-color: var(--color-status-p) !important;
                background-color: var(--color-status-p) !important;

                color: #{$color-txt-p-dm} !important;

                &.disabled {
                    pointer-events: none;
                }

                &:hover {
                    background-color: var(--color-status-t) !important;
                }

            }

            .spinner {
                width: 18px;
                height: 18px;
                border: 3px solid var(--color-txt-p);
                border-right-color: transparent;
                border-radius: 50%;

                -webkit-animation: spinnerAnimation 0.75s linear infinite;
                animation: spinnerAnimation 0.75s linear infinite;
            }

        }

    }

}

.dark-mode {

    .formContainer {

        .formActionsContainer {

            button {
    
                &.outline {
                    border-color: var(--color-txt-p) !important;

                    color: var(--color-txt-p) !important;
                }

                &.status {
                    border-color: var(--color-status-p) !important;
                    background-color: var(--color-status-p) !important;

                    color: #{$color-txt-p-dm} !important;

                    &:hover {
                        background-color: var(--color-status-t) !important;
                    }

                }

            }

        }

    }

}

@keyframes spinnerAnimation {
    0% {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}