@import '../../../../styles/base/vars.scss';
@import '../../../../styles/modules/mode.scss';

#mainSystem_Register_Cliente {

    #basicInformationsGrid {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 16px;
        width: 100%;
        margin-bottom: 16px;
        
    
        .basicInformationsContent {
            display: flex;
            flex-direction: column;
            width: 100%;
    
            .sectionBoxDataContainer {
                height: 100%;
            }
    
        }
        .atentionLevelContent {
            display: flex;
            flex-direction: column;
            width: 100%;
    
            .sectionBoxDataContainer {
                height: 200px;
    
                #atentionLevelContainer {
                    display: flex;
                    flex-direction: row;
                    gap: 12px;
                    width: 100%;
                    height: 100%;
    
                    .atentionBar {
                        flex: 1;
                        height: 100%;
                        border-radius: 8px;
                        
                        &:nth-child(1){
                            background-color: #F3FF6D;
                        }
    
                        &:nth-child(2){
                            background-color: #5DBF4E;
                        }
    
                        .atentionBarTextContainer {
                            display: flex;
                            flex-direction: column;
                            padding: 4px 0;
                            margin-top: 24px;
                            background-color: var(--color-layout-box);
    
                            transition: #{$transition-theme};
    
                            span {
                                color: var(--color-txt-t);
                                font-size: 12px;
    
                                transition: #{$transition-theme};
                            }
    
                        }
    
                    }
    
                }
    
            }
    
        }
    
    }

    .tabContainer {

        #listMedvazao {

            .columnContent {
                display: flex;
                flex-direction: column;

                .title {
                    color: var(--color-txt-p) !important;
                }

            }

        }

    }

}