@import '../../../styles/base/vars.scss';
@import '../../../styles/modules/mode.scss';
@import '../../../styles/modules/status.scss';

#formLoginContainer {
    display: grid;
    grid-template-columns: auto 200px 1fr;
    height: 100vh;
    background-color: #{$color-layout-foreground-lm};

    overflow: hidden;

    .loginContent {
        position: relative;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px;
    
        &:nth-child(1){
            align-items: flex-start;
            background-color: #{$color-theme-p};
    
            h1, h2 {
                color: #{$color-txt-p-dm};
                font-size: 40px;
                text-align: left;
            }
    
            h1 {
                font-weight: bold;
            }
    
        }
    
        &:nth-child(2){
            padding: 0;
            background-color: #{$color-layout-p-lm};
        }
    
        &:nth-child(3){
            background-color: #{$color-layout-p-lm};
    
            form {
                width: auto !important;
    
                #formContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 24px;
                    width: 320px;
    
                    img {
                        margin-bottom: 24px;
                    }

                    .message {
                        width: 100%;

                        color: var(--color-status-error-p);
                        text-align: center;
                    }

                    .forgotPWD {
                        color: #{$color-txt-t-lm};

                        cursor: pointer;
                        transition: #{$transition-default};

                        &:hover {
                            color: #{$color-txt-p-lm};
                        }

                    }
            
                    .inputContainer {
                        position: relative;
            
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 12px;
                        width: 100%;
                        padding: 8px 24px 8px 12px;
                        border: 2px solid #{$color-divider-lm};
                        border-radius: 4px;
            
                        &::before {
                            content: '';
            
                            position: absolute;
                            top: -2px;
                            bottom: -2px;
                            left: -2px;
            
                            border: 2px solid #{$color-theme-p};
                            border-radius: 4px;
                        }
    
                        svg path {
                            fill: #{$color-theme-p} !important;
                        }
    
                        input {
                            flex-grow: 1;
                            background-color: transparent !important;
    
                            color: #{$color-txt-p-lm};
                            font-size: 16px;
                        }
            
                    }
    
                    button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;
                        width: 100%;
                        padding: 16px 32px;
                        border-radius: 8px;
                        background-color: #{$color-theme-p};
    
                        color: #{$color-txt-p-dm};
                        font-size: 14px;
                        font-weight: bold;
    
                        cursor: pointer;
                        transition: #{$transition-default};
    
                        &:hover {
                            background-color: #{$color-theme-p-dark};
                        }

                        &.status {

                            &.disabled {
                                pointer-events: none;
                            }

                        }

                        .spinner {
                            width: 18px;
                            height: 18px;
                            border: 3px solid var(--color-txt-p);
                            border-right-color: transparent;
                            border-radius: 50%;

                            -webkit-animation: spinnerAnimation 0.75s linear infinite;
                            animation: spinnerAnimation 0.75s linear infinite;
                        }
    
                    }
    
                    a {
                        color: #{$color-txt-t-lm};
                        font-size: 14px;
                        font-weight: bold;
                        text-decoration: underline;
    
                        &:hover {
                            color: #{$color-txt-s-lm};
                        }
    
                    }
            
                }
    
            }
    
        }
    
        .custom-shape-divider-top-1640025532 {
            width: calc(100vh + 1px);
            height: 200px;
    
            line-height: 0;
    
            transform: rotate(270deg);
            overflow: hidden;
    
            svg {
                position: relative;
        
                display: block;
                width: 100%;
                height: 100%;
    
                path {
                    fill: #{$color-theme-p};
                }
    
            }
    
        }
    
    }

}

@keyframes spinnerAnimation {
    0% {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}