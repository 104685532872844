@import '../../styles/base/vars.scss';

header {
    position: fixed;
    top: 0;

    height: 64px;
    background-color: var(--color-layout-header);
    box-shadow: #{$color-box-shadow-s};

    z-index: 10;

    nav {
        width: 100%;
        height: 100%;
        padding: 8px 24px;

        #headerContainer {
            display: grid;
            grid-template-columns: auto calc(228px - 24px) 1fr auto auto;
            gap: 0 24px;
            height: 100%;

            .headerContent {
                display: flex;
                flex-direction: row;
                align-items: center;

                &.logo {

                    img {
                        width: auto;
                        height: 48px;
                    }

                }

                &.search {

                    #headerSearch {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0 12px;
                        min-width: 300px;
                        max-width: 500px;
                        height: 40px;
                        padding: 0 16px;
                        border-radius: 16px;
                        background-color: var(--color-layout-foreground);

                        transition: #{$transition-theme};

                        input {
                            flex-grow: 1;
                            background-color: transparent;

                            color: var(--color-txt-t);
                            font-size: 16px;

                            transition: #{$transition-theme};

                            &::placeholder {
                                color: var(--color-disabled-p);
                            }

                            &:focus {
                                color: var(--color-txt-p) !important;
                            }

                        }
                        
                        #headerSearchIcon {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                color: var(--color-disabled-p) !important;

                                cursor: pointer;
                                transition: 0.1s ease-in-out;

                                &:hover {
                                    color: var(--color-txt-p) !important;
                                }

                            }

                        }
                        
                    }

                }

                &.menu, &.options, &.user {

                    .headerOption {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        padding: 8px;
                        border-radius: 24px;

                        cursor: pointer;
                        transition: 0.1s ease-in-out;

                        &:hover {
                            background-color: var(--color-action-hover);
                        }

                        .headerOptionLabel {
                            margin-left: 8px;

                            color: #{$color-theme-p};
                            font-size: 18px;
                            font-weight: bold;
                        }

                    }

                }

            }

        }

    }

}

.dark-mode header {

    .menu, .options, .user {

        .headerOption svg path, .headerOptionLabel {
            fill: var(--color-txt-t) !important;
            color: var(--color-txt-t) !important;
        }

    }

}