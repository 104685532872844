@import '../../../../styles/base/vars.scss';
@import '../../../../styles/modules/mode.scss';
@import '../../../../styles/modules/status.scss';

#mainSystem_Register_RamoAtividade {

    #message {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: 24px;

        span {
            color: var(--color-status-p);
        }

    }

    #control {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 24px;

        button {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            padding: 12px 32px;
            border: 1px solid #{$color-theme-p};
            border-radius: 8px;

            font-size: 14px;
            font-weight: 700;

            cursor: pointer;
            transition: #{$transition-default};

            &.default {
                background-color: #{$color-theme-p};

                color: #{$color-txt-p-dm};

                &:hover {
                    border-color: #{$color-theme-p-dark};
                    background-color: #{$color-theme-p-dark};
                }

            }

            &.outline {
                background-color: transparent;

                color: #{$color-theme-p};

                &:hover {
                    background-color: #{$color-theme-p-hover};
                }

            }

            &.status {
                border-color: var(--color-status-p) !important;
                background-color: var(--color-status-p) !important;

                color: #{$color-txt-p-dm} !important;

                &.disabled {
                    pointer-events: none;
                }

                &:hover {
                    background-color: var(--color-status-t) !important;
                }

            }

            .spinner {
                width: 18px;
                height: 18px;
                border: 3px solid #{$color-txt-p-dm};
                border-right-color: transparent;
                border-radius: 50%;

                -webkit-animation: spinnerAnimation 0.75s linear infinite;
                animation: spinnerAnimation 0.75s linear infinite;
            }

        }

    }

}

.darkMode #mainSystem_Register_RamoAtividade {

    #control {

        button {
    
            &.outline {
                border-color: var(--color-txt-p) !important;

                color: var(--color-txt-p) !important;
            }

            &.status {
                border-color: var(--color-status-p) !important;
                background-color: var(--color-status-p) !important;

                color: #{$color-txt-p-dm} !important;

                &:hover {
                    background-color: var(--color-status-t) !important;
                }

            }

        }

    }

}

@keyframes spinnerAnimation {
    0% {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}