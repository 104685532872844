@import '../../styles/base/vars.scss';
@import '../../styles/modules/mode.scss';

#wrapper {
    position: relative;

    overflow: hidden;
    
    &.center, &.default {
        min-height: 100vh !important;
        background-color: var(--color-layout-foreground);
    }

    &.center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &.form {
        overflow: hidden;
    }

    &.default {
        display: flex;
        flex-direction: row;
        padding-top: 64px;

        main {
            width: 100%;
            height: calc(100vh - 64px);

            transition: 0.5s ease-in-out;
    
            #mainContainer {
                padding: 24px;
            }
    
        }

    }

    &.sidebar-open {

        main {
            padding-left: 300px;
        }

    }

}