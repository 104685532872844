@import '../../styles/modules/mode.scss';
@import '../../styles/modules/status.scss';
@import '../../styles/base/vars.scss';

.listContainer {
    position: relative;

    width: 100%;

    .listSection {
        width: 100%;

        &.header {

            .listColumn {
                padding: 12px 0;

                color: var(--color-txt-s);
                font-size: 14px;
                font-weight: bold;
            }

        }

        &.main {
            display: grid;
            max-height: 70vh;
            padding-bottom: 16px;

            .listRowContainer {
                transition: 0.1s ease-in-out;

                &:hover {
                    background: var(--color-action-hover);
                }

                &.status .listColumn {
                    color: var(--color-status-p) !important;
                }

                .listColumn {
                    position: relative;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    gap: 0 8px;
                    padding: 8px 0;
                    
                    color: var(--color-txt-t);
                    font-size: 14px;

                    .listOption {
                        width: 20px;
                        height: 20px;

                        svg {
                            cursor: pointer;

                            &:hover path {
                                fill: var(--color-txt-p) !important;
                            }

                            path {
                                fill: var(--color-txt-t) !important;

                                transition: #{$transition-default};
                            }

                        }

                        &.status svg {

                            &:hover path {
                                fill: var(--color-status-t) !important;
                            }

                            path {
                                fill: var(--color-status-p) !important;
                            }

                        }

                    }

                    input {
                        background-color: var(--color-action-hover);

                        color: var(--color-txt-t);
                        font-size: 14px;

                        transition: #{$transition-theme};

                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            appearance: none;
                        }

                        &:hover, &:focus {
                            border-color: #{$color-theme-p};

                            color: var(--color-txt-p);
                        }

                        &::before {
                            
                            width: 100%;
                            height: 4px;
                            background-color: red;

                            z-index: 11;
                        }

                    }

                }

            }

        }

        a {
            width: 100%;
        }

        .listRow {
            width: 100%;

            transition: 0.1s ease-in-out;

            &.zebra {
                background-color: var(--color-disabled-s)
            }

            .listRowContainer {
                display: grid;
                column-gap: 12px;
                
                width: 100%;

                &.padding {
                    padding: 0 24px;
                }

                &.noPadding {
                    padding: 0 8px;
                }

                .listColumn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    &.center {
                        justify-content: center;
                    }

                }

            }

        }

    }

}