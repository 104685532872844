@import '../../../styles/base/vars.scss';
@import '../../../styles/modules/mode.scss';

#formChangeContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100vh;
    background-color: #{$color-layout-p-lm};

    overflow: hidden;

    .loginContent {
        position: relative;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px;

        &:nth-child(1){
            padding: 0;
            
            img {
                width: auto;
                height: 100%;
            }

        }

        &:nth-child(2){

            form {
                width: auto !important;
    
                #formContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 24px;
                    width: 320px;
    
                    img {
                        margin-bottom: 24px;
                    }

                    .formTitle {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin-bottom: 24px;

                        h3 {
                            margin-bottom: 4px;

                            color: #{$color-theme-p};
                            font-size: 32px;
                            font-weight: bold;
                        }

                        h4 {
                            color: #{$color-txt-t-lm};
                            font-size: 16px;
                        }

                    }
            
                    .inputContainer {
                        position: relative;
            
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 12px;
                        width: 100%;
                        padding: 8px 24px 8px 12px;
                        border: 2px solid #{$color-divider-lm};
                        border-radius: 4px;
            
                        &::before {
                            content: '';
            
                            position: absolute;
                            top: -2px;
                            bottom: -2px;
                            left: -2px;
            
                            border: 2px solid #{$color-theme-p};
                            border-radius: 4px;
                        }
    
                        svg path {
                            fill: #{$color-theme-p} !important;
                        }
    
                        input {
                            flex-grow: 1;
                            background-color: transparent !important;
    
                            color: #{$color-txt-p-lm};
                            font-size: 16px;
                        }
            
                    }
    
                    input[type = submit]{
                        width: 100%;
                        padding: 16px 32px;
                        border-radius: 8px;
                        background-color: #{$color-theme-p};
    
                        color: #{$color-txt-p-dm};
                        font-size: 16px;
                        font-weight: bold;
    
                        cursor: pointer;
                        transition: 0.2s ease-in-out;
    
                        &:hover {
                            background-color: #{$color-theme-p-dark};
                        }
    
                    }
    
                    a {
                        color: #{$color-txt-t-lm};
                        font-size: 14px;
                        font-weight: bold;
                        text-decoration: underline;
    
                        &:hover {
                            color: #{$color-txt-s-lm};
                        }
    
                    }
            
                }
    
            }
            
        }
    
    }

}