@import '../base/vars.scss';

.status {

    &.error {
        --color-status-p: #{$color-status-error-p};
        --color-status-s: #{$color-status-error-s};
        --color-status-t: #{$color-status-error-t};
    }
    
    &.warning {
        --color-status-p: #{$color-status-warning-p};
        --color-status-s: #{$color-status-warning-s};
        --color-status-t: #{$color-status-warning-t};
    }
    
    &.info {
        --color-status-p: #{$color-status-info-p};
        --color-status-s: #{$color-status-info-s};
        --color-status-t: #{$color-status-info-t};
    }
    
    &.success {
        --color-status-p: #{$color-status-success-p};
        --color-status-s: #{$color-status-success-s};
        --color-status-t: #{$color-status-success-t};
    }

    &.disabled {
        --color-status-p: #{$color-status-disabled-p};
        --color-status-s: #{$color-status-disabled-s};
        --color-status-t: #{$color-status-disabled-t};
    }

    &.values{
        --color-status-p:  #{$color-status-values-p};
        --color-status-s:  #{$color-status-values-s};
        --color-status-t:  #{$color-status-values-t};
    }

    &.analise{
        --color-status-p:  #{$color-status-analise-p};
        --color-status-s:  #{$color-status-analise-s};
        --color-status-t:  #{$color-status-analise-t};
    }

    &.representativa{
        --color-status-p:  #{$color-status-representativa-p};
        --color-status-s:  #{$color-status-representativa-s};
        --color-status-t:  #{$color-status-representativa-t};
    }
}