@import '../../../styles/base/vars.scss';
@import '../../../styles/modules/mode.scss';

#invalidContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    #invalidBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 300px;

        h1 {
            margin-bottom: 4px;

            color: #{$color-theme-p};
            font-size: 32px;
            font-weight: bold;
        }

        h2 {
            color: #{$color-txt-t-lm};
            font-size: 16px;
        }

        img {
            width: 100%;
            margin: 32px 0;
        }
    
        a {
            width: 100%;
            padding: 16px 32px;
            border-radius: 8px;
            background-color: #{$color-theme-p};
    
            color: #{$color-txt-p-dm};
            font-size: 16px;
            font-weight: bold;
            text-align: center;
    
            cursor: pointer;
            transition: 0.2s ease-in-out;
    
            &:hover {
                background-color: #{$color-theme-p-dark};
            }
        }

    }

}