@import '../../styles/base/vars.scss';
@import '../../styles/modules/mode.scss';

/*******************************************************
	Tab
*******************************************************/

.tabContainer {
    width: 100%;

    .group {
        width: 100%;

        &.tabLabelGroup {
            display: grid;
            background-color: #F5F4F7;

            transition: #{$transition-theme};

            .tabLabel {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 16px 24px 12px;
                border-bottom: 2px solid var(--color-divider);

                color: var(--color-disabled-p);
                font-size: 16px;
                font-weight: bold;
                text-align: center;

                cursor: pointer;
                transition: #{$transition-theme};

                &:hover {
                    border-color: var(--color-txt-t);
                    background-color: var(--color-action-hover);

                    color: var(--color-txt-t);
                }

                &.active {
                    border-color: #{$color-theme-p};

                    color: #{$color-theme-p};

                    &:hover {
                        background-color: #{$color-theme-p-hover};
                    }

                }

            }

        }

        &.tabPanelGroup {
            
            .tabPanel {
                display: none;
                width: 100%;

                &.active {
                    display: block;
                }

                .tabPanelContainer {
                    width: 100%;
                    height: 100%;
                    padding: 24px;

                    .tabPanelTitle {
                        color: var(--color-txt-s);
                        font-size: 24px;
                        font-style: italic;
                        font-weight: bold;

                        transition: #{$transition-theme};
                    }

                }

            }

        }

    }

}

.dark-mode .tabContainer .group {

    &.tabLabelGroup {
        background-color: #{$color-layout-t-dm} !important;

        .tabLabel {

            &.active {
                border-color: var(--color-txt-p) !important;

                color: var(--color-txt-p) !important;

                &:hover {
                    background-color: var(--color-action-hover) !important;
                }

            }

        }

    }

}