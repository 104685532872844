@import '../../styles/base/vars.scss';
@import '../../styles/modules/mode.scss';
@import '../../styles/modules/status.scss';

.menuWrapper {
    position: relative;
}

.menuContainer {
    top: 0;
    right: 0;
    position: absolute;
    min-width: 200px;
    max-width: 400px;
    padding: 8px 0;
    border-radius: 8px;
    background-color: var(--color-layout-p);
    box-shadow: #{$color-box-shadow-p};

    z-index: 1001;

    .menuHeader {
        width: 100%;
        margin: 8px 0 16px;

        .menuLabel {
            padding: 0px 16px;
    
            color: var(--color-txt-p);
            font-size: 14px;
            font-weight: bold;
            text-align: left;
        }

    }
    
    .menuList {
        display: flex;
        flex-direction: column;
        width: 100%;

        .menuOption {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0 12px;
            width: 100%;
            padding: 8px 16px;

            cursor: pointer;
            transition: #{$transition-default};

            &:hover {
                background-color: var(--color-action-hover);
            }

            svg path {
                fill: var(--color-txt-s) !important;
            }

            .label {
                color: var(--color-txt-s);
                font-size: 14px;
            }

        }

    }

}