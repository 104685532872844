@import '../../styles/base/vars.scss';

.sidebar-open #sidebar {
    left: 0;
}

#sidebar {
    position: absolute;
    left: -300px;

    display: flex;
    flex-direction: column;
    width: 300px;
    height: calc(100vh - 64px);
    background-color: #{$color-theme-p};

    z-index: 11;
    transition: 0.5s ease-in-out;

    #sidebarSectionGroup {
        width: 100%;
        height: 100%;
        padding: 24px 0;

        .sidebarSection {
            width: 100%;

            &:not(:last-child){
                margin-bottom: 8px;
            }

            .sidebarOptionContainer {
                width: 100%;

                cursor: pointer;
                transition: 0.1s ease-in-out;

                .sidebarOptionContent {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    padding: 8px 24px;

                    &.active {
                    
                        .sidebarOptionLabel {
                            color: #{$color-txt-p-dm};
                            font-weight: bold;
                        }
    
                    }

                }

                &:hover {
                    background-color: #{$color-action-hover-lm};
                }

                &.focus {
                    background-color: #{$color-action-hover-dm} !important;
                }

                &.header {

                    .sidebarOptionLabel {
                        color: #{$color-txt-p-dm};
                        font-size: 18px;
                        font-weight: bold;
                    }

                }

                &.default {

                    .sidebarOptionLabel {
                        color: #{$color-txt-t-dm};
                        font-size: 16px;
                    }

                }

                .sidebarOptionIcon {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;

                    svg path {
                        fill: #{$color-txt-p-dm} !important;
                    }

                }

                .sidebarOptionLabel {
                    flex-grow: 1;
                }

                .sidebarOptionIconOpen {
                    width: 24px;
                    height: 24px;
                    
                    transition: 0.2s ease-in-out;

                    &.open {
                        transform: rotate(90deg);
                    }

                    svg path {
                        fill: #{$color-txt-p-dm} !important;
                    }

                }

            }

        }

        .sidebarLoading {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

    }

}